export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const ADD_PRODUCER = "ADD_PRODUCER";
export const PRODUCER_LIST_REQUEST = "PRODUCER_LIST_REQUEST";
export const PRODUCER_LIST_SUCCESS = "PRODUCER_LIST_SUCCESS";
export const PRODUCER_LIST_FAIL = "PRODUCER_LIST_FAIL";

export const ADD_BUSPARTNER = "ADD_BUSPARTNER";
export const BUSPARTNER_LIST_REQUEST = "BUSPARTNER_LIST_REQUEST";
export const BUSPARTNER_LIST_SUCCESS = "BUSPARTNER_LIST_SUCCESS";
export const BUSPARTNER_LIST_FAIL = "BUSPARTNER_LIST_FAIL";

export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const ORGANIZATION_LIST_REQUEST = "ORGANIZATION_LIST_REQUEST";
export const ORGANIZATION_LIST_SUCCESS = "ORGANIZATION_LIST_SUCCESS";
export const ORGANIZATION_LIST_FAIL = "ORGANIZATION_LIST_FAIL";

export const ADD_MATERIAL = "ADD_MATERIAL";
export const MATERIAL_LIST_REQUEST = "MATERIAL_LIST_REQUEST";
export const MATERIAL_LIST_SUCCESS = "MATERIAL_LIST_SUCCESS";
export const MATERIAL_LIST_FAIL = "MATERIAL_LIST_FAIL";

export const ADD_CONTRACT = "ADD_CONTRACT";
export const CONTRACT_LIST_REQUEST = "CONTRACT_LIST_REQUEST";
export const CONTRACT_LIST_SUCCESS = "CONTRACT_LIST_SUCCESS";
export const CONTRACT_LIST_FAIL = "CONTRACT_LIST_FAIL";

export const ADD_PUR_ORDER = "ADD_PUR_ORDER";
export const PUR_ORDER_LIST_REQUEST = "PUR_ORDER_LIST_REQUEST";
export const PUR_ORDER_LIST_SUCCESS = "PUR_ORDER_LIST_SUCCESS";
export const PUR_ORDER_LIST_FAIL = "PUR_ORDER_LIST_FAIL";

export const ADD_INVOICE = "ADD_INVOICE";
export const INVOICE_LIST_REQUEST = "INVOICE_LIST_REQUEST";
export const INVOICE_LIST_SUCCESS = "INVOICE_LIST_SUCCESS";
export const INVOICE_LIST_FAIL = "INVOICE_LIST_FAIL";
